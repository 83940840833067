.nav {
    background-color: rgb(51 51 51);
}

.image-video {
    max-width: 100%;
    height: auto;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.logo {
    img {
        @extend .image-video;
        display: block;
        object-position: center;
        object-fit: cover;
        @extend .mx-auto;
        height: 100%;
    }
}

.topLink {
    text-decoration-line: underline;
    color: rgb(255 255 255);
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.75rem;
    padding-top: 2.1rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: inline-block;
}

.container {
    width: 100%;
}

.navContent {
    padding-top: 2.688rem;
    @extend .mx-auto;
}

.content {
    @extend .mx-auto;
}

.contentBox {
    overflow: hidden;
}

.boxInner {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.boxInnerContent {
    text-align: center;
    background-color: rgb(239 235 225);
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 100%;
    min-height: 18rem;
    display: flex;
    padding: 10px;

    img {
        width: 8rem;
        display: inline-block;
        @extend .image-video;
    }
}

.boxDescription {
    padding-left: 2rem;
    padding-right: 2rem;
}

.boxBtn {
    color: rgb(256, 256, 256);
    font-size: 1.125rem;
    line-height: 1.75rem;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: rgb(51 51 51);
    display: inline-block;
}

.boxFooterText {
    font-style: italic;
    display: block;
}

.imgGridContent {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.imgGridWrapper {
    display: flex;
    flex-wrap: wrap;
}

.imgItem {
    flex-wrap: wrap;
    display: flex;
    width: 25%;
}

.imgItemInner {
    width: 100%;
    padding: 0.25rem;

    img {
        object-position: center;
        object-fit: cover;
        // border-radius: 0.5rem;
        width: 100%;
        height: 100%;
        display: block;
        @extend .image-video;
    }
}

.container {
    max-width: 390px;
}

.logo {
    padding-top: 1rem;
    img {
        width: 75%;
    }
}

.side {
    text-align: center;
}

.contentBox {
    width: 100%;
}
.boxInnerContent {
    img {
        padding-top: 2rem;
    }
}
.boxDescription {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.boxBtn {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.boxFooterText {
    font-size: 0.75rem;
    line-height: 1rem;
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
    .imgGridWrapper {
        margin: -0.5rem;
    }
    .imgItemInner {
        padding: 0.5rem;
    }
    .boxInnerContent {
        padding: 0;
    }
}

@media (min-width: 976px) {
    .logo {
        img {
            width: 100%;
        }
    }

    .container {
        max-width: 976px;
    }

    .navContent {
        padding-top: 3rem;
        display: flex;
        justify-content: space-between;
    }

    .content {
        display: flex;
        justify-content: space-between;
    }

    .contentBox {
        width: 49%;
    }

    .boxInner {
        height: 100%;
        padding-top: 3rem;
    }

    .boxInnerContent {
        height: 100%;

        img {
            padding-top: 4rem;
        }
    }

    .boxDescription {
        font-size: 1.025rem;
        line-height: 1.55rem;
    }

    .boxBtn {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }

    .boxFooterText {
        font-size: 0.875rem;
        line-height: 1.25rem;
        padding: 0 2.188rem;
    }

    .imgGrid {
        width: 100%;
        padding-right: 1.75rem;
    }

    .imgGridContent {
        padding-top: 3rem;
    }
}
